import React, { createContext, Suspense, useCallback, useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import axios, { AxiosError } from "axios";
import Login from "./Components/Login";
import Organizations from "./Components/Organizations";
import Status from "./Components/Status";
import { useLocalStorage } from "Hooks/useLocalStorage";
import Overlay from "Components/Overlay";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

function getClient() {
  const client = axios.create();

  client.interceptors.request.use((r) => {
    return r.url ? { ...r, url: `https://cors.dedibyte.ru/${r.url}` } : r;
  });

  return client;
}

export type Image = { imageId: string; imageUrl: string };
export type CatalogItem = {
  id: string;
  name: string;
  parentGroup?: string;
  isIncludedInMenu: boolean;
  images: Image[];
  order: number;
  productCategoryId: string;
  tags: string[];
};
export type Product = CatalogItem & {
  price: number;
  weight: number;
  type: "dish" | "modifier";
  description?: string;
  energyAmount?: number;
  energyFullAmount?: number;
  carbohydrateAmount?: number;
  carbohydrateFullAmount?: number;
  fatAmount?: number;
  fatFullAmount?: number;
  fiberAmount?: number;
  fiberFullAmount?: number;
  productCategoryId: string;
  tags: string[];
};
export type ProductCategory = {
  id: string;
  name: string;
};

export type Category = CatalogItem & {};
export type Nomenclature = { products: Product[]; categories: Category[]; productCategories: ProductCategory[] };
export type OverlayContextType = { show: () => void; fullscreen: () => void };

const NomenclatureContext = createContext<Nomenclature>({ categories: [], products: [], productCategories: [] });
export const OverlayContext = createContext<OverlayContextType>({
  show: () => {
    throw new Error("Cant use overlay out of provider");
  },
  fullscreen: () => {
    throw new Error("Cant use overlay out of provider");
  },
});

export function useNomenclature(): Nomenclature {
  return useContext(NomenclatureContext);
}

function load(Component: any) {
  return <Component />;
}

function Configurator({
  login,
  password,
  org,
  canLogout = false,
  canUpdate = false,
  organizations = [],
  errors = [],
  onSignIn,
  onOrgSelected,
  onLogout,
  onUpdate,
}: {
  login?: string;
  password?: string;
  org?: string;
  organizations?: [];
  errors: string[];
  canLogout?: boolean;
  canUpdate?: boolean;
  onSignIn?: (login: string, password: string) => void;
  onLogout?: () => void;
  onOrgSelected?: (id: string) => void;
  onUpdate?: () => void;
}) {
  return (
    <>
      <Login
        defaultLogin={login}
        defaulPassword={password}
        isLogout={canLogout}
        onLogin={onSignIn}
        onLogout={onLogout}
      />
      <Organizations items={organizations} onSelected={(e) => onOrgSelected?.(e.id)} selected={org} />
      {canUpdate && (
        <div>
          <button onClick={onUpdate}>Обновить</button>
        </div>
      )}
      {errors.length > 0 && (
        <div>
          {errors.map((e) => (
            <p>{e}</p>
          ))}
        </div>
      )}
    </>
  );
}

export default function App() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [login, setLogin] = useLocalStorage<string>("login");
  const [password, setPassord] = useLocalStorage<string>("password");
  const [token, setToken] = useLocalStorage<string>("token");
  const [organization, setOrganization] = useLocalStorage<string>("org");
  const [organizations, setOrganizations] = useState<[]>([]);
  const [products, setProducts] = useLocalStorage<Product[]>("products", []);
  const [categories, setCategories] = useLocalStorage<Category[]>("categories", []);
  //productCategories
  const [productCategories, setProductCategories] = useLocalStorage<ProductCategory[]>("productCategories", []);

  const [errors, setErrors] = useState<string[]>([]);
  const fullScreenHandle = useFullScreenHandle();

  const isAuthorized = useCallback((): boolean => {
    return !!token;
  }, [token]);

  const authorize = useCallback((login: string, password: string) => {
    setLogin(login);
    setPassord(password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    setPassord();
    setLogin();
    setToken();
    setOrganization();
    setOrganizations([]);
    setProducts([]);
    setCategories([]);
    setProductCategories([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = useCallback((token: string, org: string) => {
    if (org && token) {
      setIsPending(true);
      getClient()
        .get(`https://iiko.biz:9900/api/0/nomenclature/${org}?access_token=${token}`)
        .then(({ data }) => {
          setCategories(data.groups);
          setProducts(data.products);
          setProductCategories(data.productCategories);
        })
        .catch((e) => {
          console.warn(e);
          setErrors((e) => [...e, `Ошибка обновления товаров: ${e}`]);
        })
        .finally(() => setIsPending(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeScreen = useCallback(() => {
    if (!fullScreenHandle.active) {
      fullScreenHandle.enter();
    } else {
      fullScreenHandle.exit();
    }
  }, [fullScreenHandle]);

  useEffect(() => {
    if (login && password && !token) {
      setIsPending(true);
      getClient()
        .get(`https://iiko.biz:9900/api/0/auth/access_token?user_id=${login}&user_secret=${password}`)
        .then(({ data }) => setToken(data))
        .catch((error: Error | AxiosError) => {
          console.warn(error);
          setErrors((e) => [...e, `Ошибка обновления товаров: ${error.message}`]);
        })
        .finally(() => setIsPending(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, password, token]);

  useEffect(() => {
    if (token) {
      setIsPending(true);
      getClient()
        .get(`https://iiko.biz:9900/api/0/organization/list?access_token=${token}`)
        .then(({ data }) => setOrganizations(data))
        .catch((e) => {
          console.warn(e);
          setErrors((e) => [...e, `Ошибка авторизации: ${e}`]);
          setToken(undefined);
        })
        .finally(() => setIsPending(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    updateData(token, organization);
  }, [organization, token, updateData]);

  if (isPending) return <Status message="Выполняется операция" />;

  return (
    <FullScreen {...{ handle: fullScreenHandle }}>
      <OverlayContext.Provider value={{ show: () => setIsOverlayVisible(true), fullscreen: changeScreen }}>
        <Overlay
          visible={!isAuthorized() || !organization || isOverlayVisible}
          onPaneClick={() => setIsOverlayVisible(false)}
          component={
            <Configurator
              login={login}
              password={password}
              organizations={organizations}
              org={organization}
              canLogout={!!login && !!password && !!token}
              canUpdate={!!token}
              errors={errors}
              onSignIn={authorize}
              onLogout={logout}
              onOrgSelected={setOrganization}
              onUpdate={() => updateData(token, organization)}
            />
          }
        >
          <Suspense fallback={<div>...Loading</div>}>
            <NomenclatureContext.Provider value={{ categories, products, productCategories }}>
              <Routes>
                <Route path="/" element={load(React.lazy(() => import("./Pages/Hot")))} />
                <Route path="/hot/:id" element={load(React.lazy(() => import("./Pages/Hot")))} />
                <Route path="/product/:id" element={load(React.lazy(() => import("./Pages/Product")))} />
                <Route path="/catalog" element={load(React.lazy(() => import("./Pages/Catalog")))} />
                <Route path="/catalog/:id" element={load(React.lazy(() => import("./Pages/Catalog")))} />
                {/* <Route>
                    <Route path="/">
                      <Route index element={load(React.lazy(() => import("./Pages/Hot")))} />
                      <Route path="/tag">
                        <Route path=":tagId" element={load(React.lazy(() => import("./Pages/Catalog")))}>
                          <Route path="group">
                            <Route path=":id" element={load(React.lazy(() => import("./Pages/Catalog")))}></Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/product/:id" element={load(React.lazy(() => import("./Pages/Product")))} />
                    <Route path="/hot/:id" element={load(React.lazy(() => import("./Pages/Hot")))} />
                  </Route> */}
              </Routes>
            </NomenclatureContext.Provider>
          </Suspense>
        </Overlay>
      </OverlayContext.Provider>
    </FullScreen>
  );
}
