export default function Organizations({
  items = [],
  selected,
  onSelected,
}: {
  items?: any[];
  selected?: string;
  onSelected?: (item: { id: string; name: string }) => void;
}) {
  return (
    <div className="organizations">
      {items.map((item) => (
        <>
          <button onClick={() => onSelected?.(item)}>{item.name}</button>
          <span>{item.id === selected ? "✅" : ""}</span>
        </>
      ))}
    </div>
  );
}
