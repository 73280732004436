import { useState } from "react";

export default function Login({
  defaultLogin,
  defaulPassword,
  isLogout = false,
  onLogin,
  onLogout,
}: {
  defaultLogin?: string;
  defaulPassword?: string;
  isLogout?: boolean;
  onLogin?: (login: string, password: string) => void;
  onLogout?: () => void;
}) {
  const [login, setLogin] = useState<string | undefined>(defaultLogin);
  const [password, setPassord] = useState<string | undefined>(defaulPassword);

  return (
    <div className="login">
      {!isLogout ? (
        <form
          className="login-form"
          onSubmit={(e) => {
            if (login && password) {
              onLogin?.(login, password);
            }

            e.preventDefault();
          }}
        >
          <input type={"text"} onChange={(e) => setLogin(e.target.value)} defaultValue={login} />
          <input type={"password"} onChange={(e) => setPassord(e.target.value)} defaultValue={password} />
          <button type="submit">Войти</button>
        </form>
      ) : (
        <>
          <span>{login}</span>
          <button onClick={onLogout}>Выйти</button>
        </>
      )}
    </div>
  );
}
