import React from "react";

export default function Overlay({
  children,
  visible = false,
  component,
  onPaneClick,
}: {
  children?: React.ReactNode;
  visible?: boolean;
  component?: React.ReactNode;
  onPaneClick?: () => void;
}) {
  return ( 
    <>
      {children}
      {visible && (
        <div className="overlay" onClick={onPaneClick}>
          <div className="overlay-content">{component}</div>
        </div>
      )}
    </>
  );
}
