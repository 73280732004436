export const DBConfig = {
  name: "padmenu-v2",
  version: 2,
  objectStoresMeta: [
    {
      store: "images",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "data", keypath: "data", options: { unique: false } },
        { name: "key", keypath: "key", options: { unique: true } },
      ],
    },
  ],
};
