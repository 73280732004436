import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initDB } from "react-indexed-db";
import { DBConfig } from "./Utils/Database";
import { HashRouter as Router } from "react-router-dom";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./Assets/Styles/main.scss";

initDB(DBConfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
